import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiUrlService {
  private apiUrl: string = environment.apiUrl;

  constructor() {}

  getApiUrl(): string {
    return this.apiUrl;
  }
}
