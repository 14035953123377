import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { Router } from "@angular/router";
import { FunctionalityService } from "mn-common";
import { HomeLinksContributionComponent } from "home-links-contribution";
import { flatMap, map, mergeMap, tap } from "rxjs/operators";
import { FeatureService } from "src/app/services/features.service";
import { Observable, of } from "rxjs";
import { SideNavigatorService } from "src/app/services/side-navigator.service";
import { ScreenSizeService } from "src/app/services/screen-size.service";
import { AuthService } from "src/app/services/auth.service";
import { ApiUrlService } from "src/app/services/api-url.service";
import { HttpClient } from "@angular/common/http";
import { CacheService } from "src/app/services/cache.service";
import { NetworkService } from "src/app/services/network.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, OnDestroy {
  showScreen = false;
  features = [];
  firstName: string;
  organizationLogoUrl: string;

  isMobile: boolean;
  isDesktop: boolean;

  constructor(
    private functionalityService: FunctionalityService,
    public sideNavigatorService: SideNavigatorService,
    private router: Router,
    private authService: AuthService,
    private featureService: FeatureService,
    private screenSizeService: ScreenSizeService,
    private apiUrlService: ApiUrlService,
    private http: HttpClient,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private cacheService: CacheService,
    private networkService: NetworkService
  ) {}

  ngOnInit(): void {
    document.body.classList.add("home-header-background");

    this.authService.getUserInfo().subscribe((data) => {
      this.firstName = data.firstname;
      this.setOrganizationLogoUrl(data.organization_id);
    });

    this.featureService.getFeatureDescriptions().then((data) => {
      this.features = data;
    });

    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    this.screenSizeService.isDesktop$.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });

    this.setComponentSize();
    window.addEventListener("resize", this.setComponentSize.bind(this));
  }

  ngOnDestroy(): void {
    document.body.classList.remove("home-header-background");
    window.removeEventListener("resize", this.setComponentSize.bind(this));
  }

  setComponentSize(): void {
    const parent = this.elRef.nativeElement.parentElement;
    if (parent) {
      const parentWidth = parent.offsetWidth;
      const parentHeight = parent.offsetHeight;

      this.renderer.setStyle(
        this.elRef.nativeElement,
        "width",
        `${parentWidth}px`
      );
      this.renderer.setStyle(
        this.elRef.nativeElement,
        "height",
        `${parentHeight}px`
      );
    }
  }

  navigateToFeature(path: string, queryParam): void {
    this.router.navigate(["/react-test", path.replace(/\//g, "")], {
      queryParams: { navigationParam: queryParam },
    });
  }

  getFeatureIconUrl(feature) {
    return `${feature.baseUrl}${feature.homeIcon}`;
  }

  getFeatureIcon(feature: any): string {
    const iconUrl = `${feature.baseUrl}${feature.homeIcon}`;

    if (this.networkService.isOnline()) {
      return iconUrl;
    } else {
      console.log("offline");
      var img = this.cacheService.getCachedItem(iconUrl);

      if (img) {
        if (typeof img === "string" && img.startsWith("data:image")) {
          // If cachedIcon is already in Base64 format, return it directly
          return img;
        } else if (img instanceof Blob) {
          // If cachedIcon is a Blob, create an object URL for it
          return URL.createObjectURL(img);
        }
        // If it's another data type or format, return it as a string or process as needed
        return img;
      } else {
        // Fallback if no cached data is available
        return "";
      }
    }
  }

  onImageError() {
    console.error("Image failed to load!!!!!!!!!!!");
    this.networkService
      .checkInternetConnection()
      .then((isConnected) => {
        if (isConnected) {
          console.log("Internet is active");
        } else {
          this.networkService.markInternetDown();
        }
      })
      .catch((error) =>
        console.error("Unexpected error in connectivity check:", error)
      );
  }

  filterFeaturesForScreenSize() {
    if (this.isDesktop) {
      return this.features;
    }
    return this.features.filter((feature) => !feature.desktopOnly);
  }

  setOrganizationLogoUrl(organizationId: string): void {
    const localStorageKey = `organizationLogo_${organizationId}`;
    const cachedLogo = localStorage.getItem(localStorageKey);
    if (cachedLogo) {
      this.organizationLogoUrl = cachedLogo;
    }

    const apiUrl = this.apiUrlService.getApiUrl();
    const logoUrl = `${apiUrl}/api/organizations/logo?organization_id=${organizationId}`;

    this.http.get(logoUrl).subscribe(
      (response: any) => {
        if (response && response.logo) {
          const logoDataUrl = `data:image/png;base64,${response.logo}`;
          this.organizationLogoUrl = logoDataUrl;

          if (cachedLogo !== logoDataUrl) {
            localStorage.setItem(localStorageKey, logoDataUrl);
          }
        } else if (!cachedLogo) {
          this.organizationLogoUrl = "assets/redesign/home/default-logo.png";
        }
      },
      (error) => {
        if (!cachedLogo) {
          this.organizationLogoUrl = "assets/redesign/home/default-logo.png";
        }
      }
    );
  }
}
