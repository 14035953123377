import { Injectable } from "@angular/core";
import { ApiUrlService } from "./api-url.service";
import { CacheService } from "./cache.service";
import { NetworkService } from "./network.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError, map, of, tap } from "rxjs";
import { lastValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FeatureService {
  private apiUrl = `${this.apiUrlService.getApiUrl()}/api/features`;
  private featureDescriptions: any[] = [];
  private slowConnectionThreshold = 3000;
  private isSlow = false;

  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private apiUrlService: ApiUrlService,
    private cacheService: CacheService
  ) {}

  // private isItSlow(): boolean {
  //   const downlinkSpeed = this.networkService.getDownlink();
  //   this.isSlow = downlinkSpeed < 0.1;
  //   return this.isSlow;
  // }

  public async getFeaturesWithTimeout(): Promise<any[]> {
    console.log("getting features");
    // this.isItSlow();
    try {
      const features = await this.http
        .get<any[]>(this.apiUrl, {
          headers: new HttpHeaders({ "Cache-Control": "no-cache" }),
          params: new HttpParams(),
        })
        .toPromise();
      // this.isSlow = false;
      console.log("features");
      return features;
    } catch (error) {
      console.error("Error fetching features:", error);
      if (this.networkService.isNetworkError(error)) {
        this.networkService.markInternetDown();
      }
      return null;
    }
  }

  public async getFeatureDescriptions(): Promise<any[]> {
    console.log("get feature descriptions ()");

    // short circuit if we are not onLine
    if (!this.networkService.isOnline()) {
      console.log("off line, getting cached fd");
      return this.loadCachedFeatureDescriptions();
    }

    const features = await this.getFeaturesWithTimeout();

    if (features == null) {
      console.log("failed to get features, getting cached fd");
      return this.loadCachedFeatureDescriptions();
    } else if (features.length === 0) return features;

    const requests = features.map((feature) => {
      console.log("mapping over feature:");
      console.log(feature);
      const featureUrl = feature.feature_url
        ? feature.feature_url + "feature-description.json"
        : feature.baseUrl + "feature-description.json";

      return lastValueFrom(
        this.cacheService.getResource(featureUrl).pipe(
          tap((description) => {
            if (description.data && description.data.offline === "true") {
              this.cacheFeatureResources(description.data);
            }
          }),
          map((response) => {
            return response?.data ? response.data : response;
          }),
          catchError((error) => {
            console.error("Error fetching feature descriptor:", error);
            return of(null);
          })
        )
      );
    });

    const descriptions = (await Promise.all(requests)).filter(
      (res) => res !== null
    );

    console.log("setting feature descriptions");
    this.featureDescriptions = descriptions;

    return descriptions;
  }

  private async cacheFeatureResources(feature: any): Promise<void> {
    const baseUrl = feature.baseUrl || feature.feature_url;
    const resourcesToCache = [
      "feature-description.json",
      "index.html",
      "bundle.js",
      feature.homeIcon,
      ...(feature.footerIcons?.flatMap((icon: any) => [
        icon.iconSrc,
        icon.negIconSrc,
      ]) || []),
    ];

    for (const resource of resourcesToCache) {
      const fullUrl = `${baseUrl}${resource}`;
      try {
        await lastValueFrom(this.cacheService.getResource(fullUrl, true));
      } catch (error) {
        console.error("Error caching resource:", fullUrl, error);
      }
    }
  }

  private async loadCachedFeatureDescriptions(): Promise<any[]> {
    console.log("loading cached feature descriptions");
    const cachedDescriptions = (
      await this.cacheService.getCachedItems((key) =>
        key.endsWith("feature-description.json")
      )
    ).map((item) => item?.data);
    console.log(cachedDescriptions);
    this.featureDescriptions = cachedDescriptions;
    return cachedDescriptions;
  }

  public getBaseUrlByBasePath(basePath: string): Promise<any> {
    console.log("get base url by base path ()");
    return this.featureDescriptions.length === 0
      ? this.getFeatureDescriptions().then(() =>
          this.findFeatureUrlByPath(basePath)
        )
      : Promise.resolve(this.findFeatureUrlByPath(basePath));
  }

  private findFeatureUrlByPath(basePath: string) {
    return this.featureDescriptions.find(
      (feature) => feature.defaultPath.replace(/\//g, "") === basePath
    )?.feature_url;
  }

  public async getFeatureDescriptionByBasePath(basePath: string): Promise<any> {
    console.log("get feature descriptions by base path ()");
    if (this.featureDescriptions.length === 0) {
      await this.getFeatureDescriptions();
    }

    return this.featureDescriptions.find(
      (feature) => feature.defaultPath.replace(/\//g, "") === basePath
    );
  }

  public async getFeatureDescriptionByBaseUrl(baseUrl: string): Promise<any> {
    console.log("get feature descriptions by base url ()");
    if (this.featureDescriptions.length === 0) {
      await this.getFeatureDescriptions();
    }

    return this.featureDescriptions.find(
      (feature) =>
        (feature.feature_url ? feature.feature_url : feature.baseUrl) ===
        baseUrl
    );
  }
}
