import { Injectable } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ScreenSizeService {
  isMobile$: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    // Mobile and tablet sizes, using Breakpoints.Handset
    this.isMobile$ = this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(map((result) => result.matches));
  }

  // Define custom desktop breakpoints (larger than tablets)
  isDesktop$: Observable<boolean> = this.breakpointObserver
    .observe(["(min-width: 1025px)"]) // Custom breakpoint for desktop (excluding iPad)
    .pipe(map((result) => result.matches));
}
