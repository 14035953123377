import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, interval, of, Subscription } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class NetworkService implements OnDestroy {
  private readonly checkUrl = "https://google.com/generate_204"; // Replace with a reliable endpoint
  private readonly pollingInterval = 30000; // 30 seconds

  private onlineStatus$ = new BehaviorSubject<boolean>(true); // Default to online
  private pollingSubscription: Subscription | null = null;

  constructor(private http: HttpClient) {}

  // Method to mark internet as down and start polling to check for reconnect
  markInternetDown() {
    this.onlineStatus$.next(false);

    // Start polling if not already polling
    if (!this.pollingSubscription) {
      this.startPolling();
    }
  }

  // Method to determine if the app is online, checking navigator.onLine and the internal status
  isOnline(): boolean {
    const isNavigatorOnline = navigator.onLine;
    const isInternalStatusOnline = this.onlineStatus$.value;

    if (isNavigatorOnline && isInternalStatusOnline) {
      // Both navigator and internal status indicate online
      return true;
    } else {
      // Either navigator or internal status indicates offline, initiate markInternetDown
      this.markInternetDown();
      return false;
    }
  }

  // Start polling every `pollingInterval` milliseconds
  private startPolling() {
    this.pollingSubscription = interval(this.pollingInterval)
      .pipe(switchMap(() => this.checkInternetConnection()))
      .subscribe((isConnected) => {
        if (isConnected) {
          this.onlineStatus$.next(true);
          this.stopPolling(); // Stop polling once reconnected
        }
      });
  }

  // Stop polling when connection is restored
  private stopPolling() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = null;
    }
  }

  // Actual method to check internet connection
  public checkInternetConnection(): Promise<boolean> {
    return this.http
      .get(this.checkUrl, { responseType: "text" })
      .pipe(
        map(() => true),
        catchError(() => of(false))
      )
      .toPromise();
  }

  // Clean up when service is destroyed
  ngOnDestroy() {
    this.stopPolling();
  }

  isNetworkError(error: any): boolean {
    // If error has status 0, it's often due to network issues
    if (error.status === 0) {
      return true;
    }

    // If there's a specific network error message, you can also check for that
    const errorMessage = error.message || error.toString();
    if (
      errorMessage.includes("ERR_INTERNET_DISCONNECTED") ||
      errorMessage.includes("NetworkError") ||
      errorMessage.includes("Failed to fetch")
    ) {
      return true;
    }

    return false;
  }
}
