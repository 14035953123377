import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SideNavigatorService {
  private hideSideNavSource = new BehaviorSubject<boolean>(true); // Initial state hidden
  hideSideNav$ = this.hideSideNavSource.asObservable(); // Expose the observable

  constructor() {}

  toggleSideNavigator(): void {
    this.hideSideNavSource.next(!this.hideSideNavSource.value); // Toggle the value
  }
}
