import { Directive, Renderer2, ElementRef } from "@angular/core";

@Directive({
  selector: "[clickable]", // This will automatically apply the directive to elements with (click)
})
export class ClickableDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.addClass(this.el.nativeElement, "clickable");
  }
}
