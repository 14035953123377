import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { ScreenSizeService } from "src/app/services/screen-size.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  username: string = "";
  password: string = "";
  email: string = "";
  loginError: string = "";
  resetSuccess: boolean = false;
  resetMode: boolean = false; // Determines whether we're in reset password mode

  isMobile: boolean;
  isDesktop: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private screenSizeService: ScreenSizeService
  ) {}

  ngOnInit(): void {
    document.body.classList.add("login-background");
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.screenSizeService.isDesktop$.subscribe((isDesktop) => {
      console.log("IsDeskTop: " + isDesktop);
      this.isDesktop = isDesktop;
    });
  }

  ngOnDestroy(): void {
    document.body.classList.remove("login-background");
  }

  onSubmit(): void {
    this.authService.login(this.username, this.password).subscribe(
      (response) => {
        this.router.navigate(["/home"]);
      },
      (error) => {
        this.loginError = error.error.detail;
        console.error("Login failed", error);
      }
    );
  }

  onResetSubmit(): void {
    this.authService.resetPassword(this.email).subscribe(
      (response) => {
        this.resetSuccess = true;
        this.resetMode = false;
        this.email = "";
      },
      (error) => {
        this.loginError = "Failed to submit reset password request.";
        console.error("Reset password failed", error);
      }
    );
  }

  passwordVisible: boolean = false;
  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  switchToReset() {
    this.resetMode = true;
    this.loginError = "";
    this.resetSuccess = false;
  }

  switchToLogin() {
    this.resetMode = false;
    this.loginError = "";
    this.resetSuccess = false;
  }
}
