<div id="app-login">
  <div id="loginLeftContentBody" *ngIf="isDesktop">
    <div id="mnTitle">
      <img src="/assets/redesign/login/login_mn_title.svg" />
    </div>
  </div>
  <div id="loginContentBody">
    <div id="loginInnerContentBody">
      <div id="mnTitle" *ngIf="!isDesktop">
        <img src="/assets/redesign/login/login_mn_title.svg" />
      </div>
      <div id="mnColoredTitle" *ngIf="isDesktop">
        <img src="/assets/redesign/login/login_mn_colored_title.svg" />
      </div>
      <div id="login-title-container">
        <span id="login-title" *ngIf="!resetMode">LOGIN</span>
        <span id="login-title" *ngIf="resetMode">RESET PASSWORD</span>
      </div>

      <div id="login-form-container">
        <form *ngIf="!resetMode" id="login-form" (ngSubmit)="onSubmit()">
          <div id="login-username-container" class="input-container">
            <input
              type="text"
              class="login-input"
              id="mn-login-username"
              placeholder="Username"
              [(ngModel)]="username"
              name="username"
            />
          </div>
          <div id="login-password-container" class="input-container">
            <input
              [type]="passwordVisible ? 'text' : 'password'"
              class="login-input"
              id="mn-login-password"
              placeholder="Password"
              [(ngModel)]="password"
              name="password"
            />
            <svg
              *ngIf="passwordVisible"
              (click)="togglePasswordVisibility()"
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="12"
              viewBox="0 0 21 12"
              fill="none"
            >
              <path
                d="M20.8437 5.68497C20.6562 5.47587 16.1475 0.558105 10.5003 0.558105C4.85302 0.558105 0.344314 5.47587 0.156827 5.68497C-0.0522756 5.91868 -0.0522756 6.272 0.156827 6.50571C0.344314 6.71481 4.8531 11.6326 10.5003 11.6326C16.1474 11.6326 20.6562 6.71481 20.8437 6.50571C21.0528 6.272 21.0528 5.91868 20.8437 5.68497ZM10.5003 10.4021C8.12577 10.4021 6.19352 8.46983 6.19352 6.09534C6.19352 3.72085 8.12577 1.7886 10.5003 1.7886C12.8747 1.7886 14.807 3.72085 14.807 6.09534C14.807 8.46983 12.8747 10.4021 10.5003 10.4021Z"
                fill="white"
              />
              <path
                d="M11.1156 4.86479C11.1156 4.24585 11.4228 3.70135 11.8902 3.36645C11.4708 3.15173 11.003 3.01904 10.5003 3.01904C8.80416 3.01904 7.42407 4.39913 7.42407 6.09529C7.42407 7.79144 8.80416 9.17153 10.5003 9.17153C12.0189 9.17153 13.2758 8.06269 13.5241 6.61382C12.2852 7.0127 11.1156 6.07568 11.1156 4.86479Z"
                fill="white"
              />
            </svg>
            <svg
              *ngIf="!passwordVisible"
              (click)="togglePasswordVisibility()"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
            >
              <g clip-path="url(#clip0_1216_7)">
                <path
                  d="M17.0389 5.96313C16.8238 5.79666 16.6076 5.63765 16.3909 5.48535L13.8635 7.76C14.0175 8.14766 14.1017 8.565 14.1017 8.99971C14.1017 11.0352 12.2617 12.6911 10.0001 12.6911C9.51708 12.6911 9.05337 12.6154 8.62263 12.4767L6.72583 14.1839C7.83349 14.5928 8.93212 14.8005 10.0001 14.8005C11.2758 14.8005 12.5951 14.5053 13.9213 13.9232C14.9628 13.466 16.0117 12.8311 17.0389 12.0363C18.7754 10.6924 19.8452 9.36358 19.8898 9.30764C20.0369 9.12371 20.0369 8.87575 19.8898 8.69178C19.8451 8.63584 18.7754 7.30701 17.0389 5.96313Z"
                  fill="white"
                />
                <path
                  d="M10.0002 11.6367C11.6156 11.6367 12.9299 10.4539 12.9299 9.00003C12.9299 8.87354 12.9193 8.74922 12.9 8.62744L9.58618 11.6099C9.72153 11.6273 9.85962 11.6367 10.0002 11.6367Z"
                  fill="white"
                />
                <path
                  d="M19.8284 0.154433C19.5996 -0.0514775 19.2286 -0.0514775 18.9998 0.154433L14.3964 4.29753C14.2379 4.2199 14.0795 4.14597 13.9213 4.07653C12.595 3.49435 11.2757 3.19917 10 3.19917C8.72437 3.19917 7.40503 3.49435 6.07878 4.0765C5.03729 4.53371 3.98839 5.16856 2.96124 5.96337C1.22476 7.30725 0.15499 8.63609 0.110303 8.69202C-0.0367676 8.87596 -0.0367676 9.12392 0.110303 9.30789C0.15499 9.36382 1.22472 10.6927 2.96124 12.0365C3.49339 12.4483 4.0314 12.8167 4.57116 13.1401L0.17167 17.0997C-0.0571582 17.3057 -0.0571582 17.6396 0.17167 17.8455C0.286084 17.9485 0.436006 18 0.585967 18C0.735928 18 0.885889 17.9485 1.00026 17.8455L19.8284 0.900167C20.0573 0.694257 20.0573 0.360343 19.8284 0.154433ZM5.89847 8.99996C5.89847 6.96451 7.73843 5.30855 10 5.30855C10.92 5.30855 11.7701 5.58256 12.4551 6.04465L11.6145 6.80121C11.1512 6.52474 10.5963 6.36324 10 6.36324C8.3846 6.36324 7.07034 7.54607 7.07034 8.99996C7.07034 9.53658 7.24979 10.036 7.55698 10.453L6.71636 11.2095C6.20292 10.5931 5.89847 9.82792 5.89847 8.99996Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1216_7">
                  <rect width="20" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div id="forgot-password-container">
            <a id="forgot-password" *ngIf="!resetMode" (click)="switchToReset()"
              >Forgot Password?</a
            >
          </div>
          <div id="login-button-container">
            <button
              id="login-button"
              type="submit"
              [disabled]="!username || !password"
            >
              Login
            </button>
          </div>
        </form>

        <!-- Reset Password Form -->
        <form *ngIf="resetMode" id="reset-form" (ngSubmit)="onResetSubmit()">
          <div class="input-container">
            <input
              type="email"
              class="login-input"
              id="reset-email"
              placeholder="Email"
              [(ngModel)]="email"
              name="email"
              required
            />
          </div>
          <div id="login-button-container">
            <button id="reset-button" type="submit" [disabled]="!email">
              Reset Password
            </button>
          </div>
        </form>

        <!-- Link to switch between Login and Reset Password -->
        <div id="reset-link-container">
          <a *ngIf="resetMode" id="reset-link" (click)="switchToLogin()"
            >Back to Login</a
          >
        </div>
      </div>

      <!-- Success message after password reset -->
      <div id="login-success-message" *ngIf="resetSuccess">
        <p>
          Your request was submitted, please check your email for a new
          password.
        </p>
      </div>

      <!-- Error message container -->
      <div id="login-error-container" *ngIf="loginError">
        <p id="login-error-message">{{ loginError }}</p>
      </div>
    </div>
  </div>
</div>
