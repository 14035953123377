import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BeaconsComponent } from "beacons";
import { LoggingComponent } from "logging";
import { PushSupportComponent } from "push-support";
import { AboutComponent } from "./components/about/about.component";
import { AdministrativeComponent } from "./components/administrative/administrative.component";
import { LoggedOutComponent } from "./components/auth/logged-out/logged-out.component";
import { NotFoundComponent } from "./components/auth/not-found/not-found.component";
import { ContactsComponent } from "./components/contacts/contacts.component";
import { DisclaimerComponent } from "./components/disclaimer/disclaimer.component";
import { HomeComponent } from "./components/home/home.component";
import { SupportComponent } from "./components/support/support.component";
import { VersionComponent } from "./components/version/version.component";
import { ReactNativeBundleComponent } from "./react-native-bundle/react-native-bundle.component";
import { LoginComponent } from "./components/auth/login/login.component";
import { AuthGuard } from "auth";

// TODO - user admin / beacons paths should onlybe included if functionality is supported

const routes: Routes = [
  { path: `login`, component: LoginComponent },
  { path: `logged-out`, component: LoggedOutComponent },
  { path: `about`, component: AboutComponent },
  { path: `administrative`, component: AdministrativeComponent },
  { path: `disclaimer`, component: DisclaimerComponent },
  { path: `contacts`, component: ContactsComponent },
  { path: `support`, component: SupportComponent },
  { path: `version`, component: VersionComponent },
  { path: `push-support`, component: PushSupportComponent },
  {
    path: `logging`,
    canActivate: [AuthGuard],
    data: { roles: ["Administrator"] },
    component: LoggingComponent,
  },
  {
    path: "drug-guide",
    loadChildren: () =>
      import("./features/drug-guide/drug-guide.module").then(
        (m) => m.DrugGuideModule
      ),
  },
  {
    path: `beacons`,
    canActivate: [AuthGuard],
    data: { roles: ["Administrator"] },
    component: BeaconsComponent,
  },
  {
    path: `user-admin`,
    canActivate: [AuthGuard],
    data: { roles: ["Administrator"] },
    loadChildren: () =>
      import("./features/user-admin/user-admin.module").then(
        (m) => m.UserAdminModule
      ),
  },
  { path: `home`, component: HomeComponent },
  {
    path: "med-calc",
    loadChildren: () =>
      import("./features/med-calc/med-calc.module").then(
        (m) => m.MedCalcModule
      ),
  },
  {
    path: `resources/pdf-viewer/:key`,
    loadChildren: () =>
      import("./features/pdf-viewer-wrapper/pdf-viewer-wrapper.module").then(
        (m) => m.PdfViewerWrapperModule
      ),
  },
  {
    path: `resources/pdf-viewer/:key/:id`,
    loadChildren: () =>
      import("./features/pdf-viewer-wrapper/pdf-viewer-wrapper.module").then(
        (m) => m.PdfViewerWrapperModule
      ),
  },
  {
    path: "release-notes",
    loadChildren: () =>
      import("./features/release-notes/release-notes.module").then(
        (m) => m.ReleaseNotesModule
      ),
  },
  { path: "react-test/:featureId", component: ReactNativeBundleComponent },
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: `**`, component: NotFoundComponent },
];

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule.forRoot(routes),
    //  {
    //   relativeLinkResolution: "legacy",
    //   //scrollPositionRestoration: "enabled",
    // }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
