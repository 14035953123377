import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router, Scroll } from "@angular/router";
import { LoggingService } from "logging";
import { filter } from "rxjs/operators";
import { environment } from "../environments/environment";
import { registerGlobalFunctions } from "../scripts/global-functions";
import { AuthService } from "./services/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild("body") bodyRef: ElementRef;
  isLoggedIn$ = false;

  constructor(
    private router: Router,
    private logging: LoggingService,
    public authService: AuthService
  ) {
    console.log("Instantiate router listener");
    this.isLoggedIn$ = this.authService.isLoggedIn();
    console.log("...");
    console.log(this.isLoggedIn$);
  }

  title = "MedicNow";

  scrollPosition = [];

  scrollSubscription;

  ngOnInit() {
    registerGlobalFunctions();

    console.log("Last release timestamp is set to:", environment.timeStamp);
    this.scrollSubscription = this.router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .subscribe((e) => {
        if (e.routerEvent.url === "/" || e.routerEvent.url === "/home") {
          this.scrollPosition = [];
        }

        if (e.position) {
          // backward
          setTimeout(() => {
            this.bodyRef.nativeElement.scrollTop = this.scrollPosition.pop();
          }, 10);
        } else if (e.anchor) {
          //anchor
          this.bodyRef.nativeElement.scroll(0, e.anchor);
        } else {
          // forward
          this.scrollPosition.push(this.bodyRef.nativeElement.scrollTop);
          setTimeout(() => {
            this.bodyRef.nativeElement.scrollTop = 0;
          }, 10);
        }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.isLoggedIn$ = this.authService.isLoggedIn(); // Re-fetch login status
        console.log("Updated login status after navigation:", this.isLoggedIn$);
      });
  }

  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }
}
