// src/app/scripts/global-functions.ts

export function registerGlobalFunctions() {
  window.onReceiveToken = function (token) {
    console.log("Received token from native app:", token);
    localStorage.setItem("nativeFCMToken", token);
  };

  window.isRunningInShell = function () {
    console.log("Received notification we are running in a shell");
    localStorage.setItem("isShell", true);
  };

  window.onReceiveDeviceInfo = function (deviceInfo) {
    console.log("Received device info from native app:", deviceInfo);
    localStorage.setItem("deviceInfo", deviceInfo);
  };
}
