// auth.service.ts
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { catchError, switchMap, tap, map } from "rxjs/operators";
import { Router } from "@angular/router";
import { jwtDecode } from "jwt-decode";
import { ApiUrlService } from "./api-url.service";

interface Role {
  role_id: number;
  role_name: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private tokenUrl = "/token";
  private userUrl = "/api/users/me";
  private rolesUrl = "/api/roles";
  private orgUrl = "/api/organizations";
  private tokenKey = "authToken";
  private userInfoKey = "userInfo";
  private rolesKey = "userRoles";
  private orgKey = "userOrganization";

  constructor(
    private http: HttpClient,
    private router: Router,
    private apiUrlService: ApiUrlService
  ) {}

  login(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
    });

    const body = new HttpParams()
      .set("username", username)
      .set("password", password);

    return this.http
      .post<any>(
        this.apiUrlService.getApiUrl() + this.tokenUrl,
        body.toString(),
        { headers }
      )
      .pipe(
        tap((response) => {
          console.log(response.access_token);
          if (response && response.access_token) {
            this.storeToken(response.access_token);
          }
        }),
        switchMap(() =>
          forkJoin({
            userInfo: this.getUserInfo(),
            userOrg: this.getOrganization(),
          })
        )
      );
  }

  private storeToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  private storeUserInfo(userInfo: any): void {
    localStorage.setItem(this.userInfoKey, JSON.stringify(userInfo));
  }

  private storeRoles(roles: Role[]): void {
    localStorage.setItem(this.rolesKey, JSON.stringify(roles));
  }

  private clearUserInfo(): void {
    localStorage.removeItem(this.userInfoKey);
  }

  private clearRoles(): void {
    localStorage.removeItem(this.rolesKey);
  }

  private clearOrganization(): void {
    localStorage.removeItem(this.orgKey);
  }

  hasRolesByName(requiredRoles: string[]): Observable<boolean> {
    return this.getRoles().pipe(
      switchMap((roles) => {
        const hasRequiredRoles = requiredRoles.every((requiredRole) =>
          roles.some((role) => role.role_name === requiredRole)
        );
        return of(hasRequiredRoles);
      })
    );
  }

  hasRoles(requiredRoles: Role[]): Observable<boolean> {
    return this.getRoles().pipe(
      switchMap((roles) => {
        const hasRequiredRoles = requiredRoles.every((role) =>
          roles.includes(role)
        );
        return of(hasRequiredRoles);
      })
    );
  }

  getToken(): string | null {
    if (this.isTokenExpired(localStorage.getItem(this.tokenKey))) {
      this.logout();
    } else {
      return localStorage.getItem(this.tokenKey);
    }
  }

  isLoggedIn(): boolean {
    return !!this.getToken();
  }

  logout(): void {
    localStorage.removeItem(this.tokenKey);
    this.clearUserInfo();
    this.clearRoles();
    this.clearOrganization();
    this.router.navigate(["/login"]);
  }

  resetPassword(userEmail: string): Observable<any> {
    const resetPasswordUrl = `/api/users/${userEmail}/forgot-password`;
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
    });

    return this.http.post<any>(
      this.apiUrlService.getApiUrl() + resetPasswordUrl,
      null,
      { headers }
    );
  }

  getUserInfo(): Observable<any> {
    const storedUserInfo = localStorage.getItem(this.userInfoKey);
    if (storedUserInfo) {
      return of(JSON.parse(storedUserInfo));
    }

    const token = this.getToken();
    if (!token) {
      this.router.navigate(["/login"]);
      return throwError("No token found");
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http
      .get<any>(this.apiUrlService.getApiUrl() + this.userUrl, { headers })
      .pipe(
        switchMap((userInfo) => {
          const userRoles = userInfo.roles ? userInfo.roles : []; // Get role_ids from userInfo

          // Fetch all roles (with role_id and role_name) from the server
          return this.http
            .get<Role[]>(this.apiUrlService.getApiUrl() + this.rolesUrl, {
              headers,
            })
            .pipe(
              map((rolesData: Role[]) => {
                // Map user role_ids to their corresponding { role_id, role_name }
                const userRoleDetails: Role[] = userRoles.map((roleId) => {
                  const matchingRole = rolesData.find(
                    (role) => role.role_id === roleId
                  );
                  return matchingRole
                    ? matchingRole
                    : { role_id: roleId, role_name: "Unknown" };
                });

                // Store the user info and the mapped roles
                this.storeUserInfo(userInfo);
                this.storeRoles(userRoleDetails);

                // Return the user info along with the role details if needed
                return { ...userInfo, roles: userRoleDetails };
              })
            );
        }),
        catchError((error) => {
          if (error.status === 404) {
            this.logout();
          }
          return throwError(error);
        })
      );
  }

  getRoles(): Observable<Role[]> {
    const storedRoles = localStorage.getItem(this.rolesKey);
    if (storedRoles) {
      return of(JSON.parse(storedRoles));
    }

    const token = this.getToken();
    if (!token) {
      this.router.navigate(["/login"]);
      return throwError("No token found");
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.getUserInfo().pipe(
      switchMap((userInfo) => {
        const userRoles = userInfo.roles ? userInfo.roles : []; // Get role_ids from userInfo

        // Fetch all roles (with role_id and role_name) from the server
        return this.http
          .get<Role[]>(this.apiUrlService.getApiUrl() + this.rolesUrl, {
            headers,
          })
          .pipe(
            map((rolesData: Role[]) => {
              // Map user role_ids to their corresponding { role_id, role_name }
              const userRoleDetails: Role[] = userRoles.map((roleId) => {
                const matchingRole = rolesData.find(
                  (role) => role.role_id === roleId
                );
                return matchingRole
                  ? matchingRole
                  : { role_id: roleId, role_name: "Unknown" };
              });

              // Optionally store the role details (role_id and role_name)
              this.storeRoles(userRoleDetails);

              // Return the array of role objects with both role_id and role_name
              return userRoleDetails;
            })
          );
      })
    ) as Observable<Role[]>;
  }

  decodeToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  getTokenExpirationDate(token: string): Date | null {
    const decoded: any = this.decodeToken(token);

    if (decoded === null || !decoded.hasOwnProperty("exp")) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token: string): boolean {
    const expirationDate = this.getTokenExpirationDate(token);
    return expirationDate === null
      ? true
      : !(expirationDate.valueOf() > new Date().valueOf());
  }

  getOrganization(): Observable<any> {
    const storedOrganization = localStorage.getItem(this.orgKey);
    if (storedOrganization) {
      console.log("Returning from localStorage");
      return of(JSON.parse(storedOrganization)); // Return cached organization from localStorage
    }

    const token = this.getToken();
    if (!token) {
      this.router.navigate(["/login"]); // Redirect to login if no token
      return throwError("No token found");
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    // Log headers and token to check if they are being applied
    console.log("Headers: ", headers.get("Authorization"));

    // Fetch user info and organization data from the API
    return this.getUserInfo().pipe(
      switchMap((userInfo) => {
        console.log("UserInfo received: ", userInfo);
        const organizationId = userInfo.organization_id; // Extract organization_id from userInfo

        // Check if the organization ID is present
        if (!organizationId) {
          console.error("No organization ID found in user info");
          return throwError("No organization ID found");
        }

        // Fetch the organization details using the organization_id
        return this.http
          .get<any>(
            `${this.apiUrlService.getApiUrl()}${this.orgUrl}/${organizationId}`,
            { headers }
          )
          .pipe(
            map((organizationData: any) => {
              console.log("Organization data received: ", organizationData);

              // Optionally store the organization details in localStorage
              localStorage.setItem(
                this.orgKey,
                JSON.stringify(organizationData)
              );

              // Return the organization details
              return organizationData;
            }),
            catchError((error) => {
              console.error("Error fetching organization:", error);
              return throwError("Failed to fetch organization");
            })
          );
      }),
      catchError((error) => {
        console.error("Error fetching user info:", error);
        return throwError("Failed to fetch user info");
      })
    );
  }
}
